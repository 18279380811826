import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import "../css/contact.css";
import Header from "./Header";

function Contact() {
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState({});
  const [surname, setSurname] = useState("");
  const [lastName, setLastName] = useState("");
  const [say, setSay] = useState("");
  const [mei, setMei] = useState("");
  const [tel, setTel] = useState("");
  const [formData, setFormData] = useState({
    stores: "",
    date: "",
    usage: "",
    staffName: "",
    substance: "",
    email: "",
  });
  const validateForm = () => {
    const newErrors = {};
    if (!formData.stores) newErrors.stores = "ご利用店舗は必須です。";
    if (!formData.date) newErrors.date = "利用日時は必須です。";
    if (!formData.usage) newErrors.usage = "利用メニューは必須です。";
    if (!formData.staffName) newErrors.staffName = "担当者名は必須です。";
    if (!formData.substance) newErrors.substance = "内容は必須です。";
    if (!formData.email) newErrors.email = "メールアドレスは必須です。";

    return newErrors;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (Object.keys(errors).length === 0) {
      navigate("/contact");
      setFormData({
        stroes: "",
        date: "",
        usage: "",
        name: "",
        substance: "",
        email: "",
      });
      setSurname("");
      setLastName("");
      setSay("");
      setMei("");
      setTel("");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="main-container">
      <Header />
      <div className="sec_contact">
        <div className="contact_inner_sec">
          <h1>feedback</h1>
          <p>お問い合わせフォーム　ご意見・ご感想</p>
          <p className="ul_p">
            <ul>
              <li>home</li>
              <li>feedback</li>
            </ul>
          </p>
          <p className="p-feedback-cont__txt">
            2営業日前後で担当者からご連絡を差し上げます。
            <br />
            ご連絡がない場合は、不具合によりメールが届いていないか、メールアドレスの誤入力の可能性があるため、大変お手数ですが再度お問い合わせください。
          </p>
          <p className="p-feedback-cont__sup">
            <sup>※</sup>
            <font _mstmutation="1">必須</font>
          </p>
          <div className="p-feedback-cont__form-inner">
            <table className="p-feedback-cont__table">
              <tbody>
                <tr>
                  <th className="v-top">
                    <font _mstmutation="1">利用店舗</font>
                    <sup className="required">※</sup>
                  </th>
                  <td>
                    <div className="selectbox">
                      <select
                        name="select_salon"
                        id="select_salon"
                        value={formData.stores}
                        onChange={handleChange}
                      >
                        <option value="" selected="selected">
                          選択してください
                        </option>
                        <optgroup label="北海道エリア" _mstlabel="14524978">
                          <option value="アトリエはるか さっぽろポールタウン店">
                            アトリエはるか さっぽろポールタウン店
                          </option>
                          <option value="アトリエはるか 大丸札幌店">
                            アトリエはるか 大丸札幌店
                          </option>
                        </optgroup>
                        <optgroup
                          label="新宿・四ツ谷エリア"
                          _mstlabel="23365823"
                        >
                          <option value="アトリエはるか アトレ四谷店">
                            アトリエはるか アトレ四谷店
                          </option>
                          <option value="アトリエはるか 新宿京王モール アネックス店">
                            アトリエはるか 新宿京王モール アネックス店
                          </option>
                          <option value="アトリエはるか ルミネ新宿店（ルミネ1）">
                            アトリエはるか ルミネ新宿店（ルミネ1）
                          </option>
                        </optgroup>
                        <optgroup
                          label="東京駅・日本橋・有楽町エリア"
                          _mstlabel="52108693"
                        >
                          <option value="アトリエはるか 東京八重洲店">
                            アトリエはるか 東京八重洲店
                          </option>
                          <option value="アトリエはるか ルミネ有楽町店（ルミネ2）">
                            アトリエはるか ルミネ有楽町店（ルミネ2）
                          </option>
                          <option value="Belle Studio by mul 高島屋日本橋店">
                            Belle Studio by mul 高島屋日本橋店
                          </option>
                        </optgroup>
                        <optgroup
                          label="上野・北千住エリア"
                          _mstlabel="22959326"
                        >
                          <option value="アトリエはるか エキア北千住店">
                            アトリエはるか エキア北千住店
                          </option>
                          <option value="アトリエはるか ルミネ北千住店">
                            アトリエはるか ルミネ北千住店
                          </option>
                          <option value="アトリエはるか アトレ上野店">
                            アトリエはるか アトレ上野店
                          </option>
                        </optgroup>
                        <optgroup label="池袋・赤羽エリア" _mstlabel="23334064">
                          <option value="アトリエはるか エチカ池袋店">
                            アトリエはるか エチカ池袋店
                          </option>
                          <option value="アトリエはるか ビーンズ赤羽店">
                            アトリエはるか ビーンズ赤羽店
                          </option>
                          <option value="アトリエはるか ルミネ池袋店">
                            アトリエはるか ルミネ池袋店
                          </option>
                          <option value="アトリエはるか エソラ池袋店">
                            アトリエはるか エソラ池袋店
                          </option>
                        </optgroup>
                        <optgroup
                          label="渋谷・表参道エリア"
                          _mstlabel="27945060"
                        >
                          <option value="アトリエはるか エチカ表参道店">
                            アトリエはるか エチカ表参道店
                          </option>
                          <option value="メイクアップライフ 渋谷ヒカリエシンクス店">
                            メイクアップライフ 渋谷ヒカリエシンクス店
                          </option>
                          <option value="アトリエはるか 渋谷マークシティ イーストモール店">
                            アトリエはるか 渋谷マークシティ イーストモール店
                          </option>
                          <option value="マニキュアハウス ラ・ポルト青山店">
                            マニキュアハウス ラ・ポルト青山店
                          </option>
                          <option value="ヘアドレッサー　渋谷サクラステージ店">
                            ヘアドレッサー　渋谷サクラステージ店
                          </option>
                        </optgroup>
                        <optgroup
                          label="恵比寿・六本木・五反田エリア"
                          _mstlabel="47609055"
                        >
                          <option value="アトリエはるか 六本木ヒルズ店">
                            アトリエはるか 六本木ヒルズ店
                          </option>
                          <option value="アトリエはるか アトレ恵比寿店">
                            アトリエはるか アトレ恵比寿店
                          </option>
                          <option value="アトリエはるか 五反田駅店">
                            アトリエはるか 五反田駅店
                          </option>
                        </optgroup>
                        <optgroup
                          label="羽田空港・蒲田エリア"
                          _mstlabel="32891898"
                        >
                          <option value="ヘアドレッサー 羽田空港店">
                            ヘアドレッサー 羽田空港店
                          </option>
                          <option value="アトリエはるか グランデュオ蒲田店">
                            アトリエはるか グランデュオ蒲田店
                          </option>
                        </optgroup>
                        <optgroup
                          label="吉祥寺・立川・町田エリア"
                          _mstlabel="39520793"
                        >
                          <option value="アトリエはるか ルミネ立川店">
                            アトリエはるか ルミネ立川店
                          </option>
                          <option value="アトリエはるか アトレ吉祥寺店">
                            アトリエはるか アトレ吉祥寺店
                          </option>
                          <option value="アトリエはるか 町田モディ店">
                            アトリエはるか 町田モディ店
                          </option>
                        </optgroup>
                        <optgroup label="千葉・埼玉エリア" _mstlabel="20411573">
                          <option value="アトリエはるか ルミネ大宮店（西口別館）">
                            アトリエはるか ルミネ大宮店（西口別館）
                          </option>
                          <option value="アトリエはるか 舞浜イクスピアリ店">
                            アトリエはるか 舞浜イクスピアリ店
                          </option>
                          <option value="アトリエはるか シャポー船橋店">
                            アトリエはるか シャポー船橋店
                          </option>
                          <option value="アトリエはるか シャポー本八幡店">
                            アトリエはるか シャポー本八幡店
                          </option>
                          <option value="マニキュアハウス シャポー船橋店">
                            マニキュアハウス シャポー船橋店
                          </option>
                          <option value="アトリエはるか ペリエ千葉店">
                            アトリエはるか ペリエ千葉店
                          </option>
                          <option value="アトリエはるか ペリエ西船橋店">
                            アトリエはるか ペリエ西船橋店
                          </option>
                          <option value="マニキュアハウス 舞浜イクスピアリ店">
                            マニキュアハウス 舞浜イクスピアリ店
                          </option>
                        </optgroup>
                        <optgroup label="横浜・川崎エリア" _mstlabel="19707415">
                          <option value="アトリエはるか ルミネ横浜店">
                            アトリエはるか ルミネ横浜店
                          </option>
                          <option value="アトリエはるか 川崎アゼリア店">
                            アトリエはるか 川崎アゼリア店
                          </option>
                          <option value="アトリエはるか 横浜ジョイナス店">
                            アトリエはるか 横浜ジョイナス店
                          </option>
                          <option value="アトリエはるか 大船ルミネウィング店">
                            アトリエはるか 大船ルミネウィング店
                          </option>
                          <option value="アトリエはるか ラゾーナ川崎プラザ店">
                            アトリエはるか ラゾーナ川崎プラザ店
                          </option>
                          <option value="Belle Studio by mul 高島屋横浜店">
                            Belle Studio by mul 高島屋横浜店
                          </option>
                        </optgroup>
                        <optgroup
                          label="名古屋・静岡エリア"
                          _mstlabel="24637561"
                        >
                          <option value="ハルカネイル イオンモール熱田店">
                            ハルカネイル イオンモール熱田店
                          </option>
                          <option value="アトリエはるか 名駅サンロード店">
                            アトリエはるか 名駅サンロード店
                          </option>
                          <option value="アトリエはるか サカエチカ店">
                            アトリエはるか サカエチカ店
                          </option>
                          <option value="アトリエはるか 名鉄金山店">
                            アトリエはるか 名鉄金山店
                          </option>
                          <option value="アトリエはるか 栄セントラルパーク店">
                            アトリエはるか 栄セントラルパーク店
                          </option>
                          <option value="アトリエはるか アスティ静岡店">
                            アトリエはるか アスティ静岡店
                          </option>
                          <option value="アトリエはるか 名古屋エスカ店">
                            アトリエはるか 名古屋エスカ店
                          </option>
                        </optgroup>
                        <optgroup
                          label="大阪・京都・三宮エリア"
                          _mstlabel="32856538"
                        >
                          <option value="アトリエはるか 心斎橋クリスタ長堀店">
                            アトリエはるか 心斎橋クリスタ長堀店
                          </option>
                          <option value="アトリエはるか エキモなんば店">
                            アトリエはるか エキモなんば店
                          </option>
                          <option value="アトリエはるか 阪急梅田茶屋町口店">
                            アトリエはるか 阪急梅田茶屋町口店
                          </option>
                          <option value="マニキュアハウス NAMBAなんなん店">
                            マニキュアハウス NAMBAなんなん店
                          </option>
                          <option value="アトリエはるか ディアモール大阪店">
                            アトリエはるか ディアモール大阪店
                          </option>
                          <option value="アトリエはるか 京都ポルタ店">
                            アトリエはるか 京都ポルタ店
                          </option>
                          <option value="アトリエはるか コトチカ京都店">
                            アトリエはるか コトチカ京都店
                          </option>
                          <option value="アトリエはるか 阪急神戸三宮店">
                            アトリエはるか 阪急神戸三宮店
                          </option>
                        </optgroup>
                        <optgroup label="広島エリア" _mstlabel="9542299">
                          <option value="アトリエはるか 紙屋町シャレオ店">
                            アトリエはるか 紙屋町シャレオ店
                          </option>
                        </optgroup>
                        <optgroup
                          label="博多・天神・鹿児島エリア"
                          _mstlabel="38705212"
                        >
                          <option value="アトリエはるか 博多アミュプラザ店">
                            アトリエはるか 博多アミュプラザ店
                          </option>
                          <option value="アトリエはるか 天神ソラリアステージ店">
                            アトリエはるか 天神ソラリアステージ店
                          </option>
                          <option value="アトリエはるか アミュプラザ鹿児島店">
                            アトリエはるか アミュプラザ鹿児島店
                          </option>
                          <option value="マニキュアハウス マイング博多駅店">
                            マニキュアハウス マイング博多駅店
                          </option>
                          <option value="マニキュアハウス 博多駅地下街店">
                            マニキュアハウス 博多駅地下街店
                          </option>
                          <option value="アトリエはるか 博多アミュエスト店">
                            アトリエはるか 博多アミュエスト店
                          </option>
                        </optgroup>
                      </select>
                      <br />
                      {formErrors.stores && (
                        <span className="contact_error">
                          {formErrors.stores}
                        </span>
                      )}
                    </div>
                  </td>
                </tr>

                <tr>
                  <th>
                    <font _mstmutation="1">利用日時</font>
                    <sup className="required">※</sup>
                  </th>
                  <td>
                    <input
                      type="text"
                      name="visit_salon_date"
                      id="visit_salon_date"
                      className=""
                      size="60"
                      value={formData.date}
                      onChange={handleChange}
                      placeholder=""
                    />
                    <br />
                    {formErrors.date && (
                      <span className="contact_error">{formErrors.date}</span>
                    )}
                  </td>
                </tr>

                <tr>
                  <th>
                    <font _mstmutation="1">利用メニュー</font>
                    <sup className="required">※</sup>
                  </th>
                  <td>
                    <input
                      type="text"
                      name="salon_menu"
                      id="salon_menu"
                      className=""
                      size="60"
                      value={formData.usage}
                      onChange={handleChange}
                      placeholder=""
                    />
                    <br />
                    {formErrors.usage && (
                      <span className="contact_error">{formErrors.usage}</span>
                    )}
                  </td>
                </tr>

                <tr>
                  <th>
                    <font _mstmutation="1">担当者名</font>
                    <sup className="required">※</sup>
                  </th>
                  <td>
                    <input
                      type="text"
                      name="salon_pic"
                      id="salon_pic"
                      className=""
                      size="60"
                      value={formData.staffName}
                      onChange={handleChange}
                      placeholder=""
                    />
                    <p className="td-txt">
                      担当者が分からない場合は「不明」とご入力ください
                    </p>
                    {formErrors.staffName && (
                      <span className="contact_error">
                        {formErrors.staffName}
                      </span>
                    )}
                  </td>
                </tr>

                <tr>
                  <th className="v-top">
                    <font _mstmutation="1">内容</font>
                    <sup className="required">※</sup>
                  </th>
                  <td>
                    <textarea
                      name="user_detail"
                      id="user_detail"
                      cols="50"
                      rows="13"
                      placeholder="ご自由にご記入ください"
                      _mstplaceholder="30481646"
                      value={formData.substance}
                      onChange={handleChange}
                    ></textarea>
                    <br />
                    {formErrors.substance && (
                      <span className="contact_error">
                        {formErrors.substance}
                      </span>
                    )}
                  </td>
                </tr>

                <tr>
                  <th>名前</th>
                  <td>
                    <span className="name-field">
                      <span className="name-field-unit">
                        <span>姓</span>
                        <input
                          type="text"
                          name="user_lastname"
                          id="user_lastname"
                          className=""
                          size="60"
                          value={surname}
                          onChange={(e) => setSurname(e.target.value)}
                          placeholder="姓"
                          _mstplaceholder="2092545"
                        />
                      </span>
                      <span className="name-field-unit">
                        <span>名</span>
                        <input
                          type="text"
                          name="user_firstname"
                          id="user_firstname"
                          className=""
                          size="60"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          placeholder="名"
                          _mstplaceholder="1958047"
                        />
                      </span>
                    </span>
                  </td>
                </tr>

                <tr>
                  <th>フリガナ</th>
                  <td>
                    <span className="name-field">
                      <span className="name-field-unit">
                        <span>セイ</span>
                        <input
                          type="text"
                          name="user_lastname_kana"
                          id="user_lastname_kana"
                          className=""
                          size="60"
                          value={say}
                          onChange={(e) => setSay(e.target.value)}
                          placeholder="セイ"
                          _mstplaceholder="2430233"
                        />
                      </span>
                      <span className="name-field-unit">
                        <span>メイ</span>
                        <input
                          type="text"
                          name="user_firstname_kana"
                          id="user_firstname_kana"
                          className=""
                          size="60"
                          value={mei}
                          onChange={(e) => setMei(e.target.value)}
                          placeholder="メイ"
                          _mstplaceholder="2433691"
                        />
                      </span>
                    </span>
                  </td>
                </tr>

                <tr>
                  <th>電話番号</th>
                  <td>
                    <input
                      type="text"
                      name="user_tel"
                      id="user_tel"
                      className=""
                      size="60"
                      value={tel}
                      onChange={(e) => setTel(e.target.value)}
                      placeholder="例）000-0000-0000"
                      _mstplaceholder="8763638"
                      style={{ width: "100%" }}
                    />
                  </td>
                </tr>

                <tr>
                  <th>
                    <font _mstmutation="1">メールアドレス</font>
                    <sup className="required">※</sup>
                  </th>
                  <td>
                    <input
                      type="email"
                      name="user_mail"
                      id="user_mail"
                      className=""
                      size="60"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="例）sell@harukamy.xyz"
                      data-conv-half-alphanumeric="true"
                      _mstplaceholder="9018321"
                      style={{ width: "100%" }}
                    />
                    <br />
                    {formErrors.email && (
                      <span className="contact_error">{formErrors.email}</span>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="contact_confirm">
            <button onClick={handleSubmit}>確認画面へ</button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
