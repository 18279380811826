import React, { useContext } from "react";
import Footer from "./Footer";
import "../css/shopList.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CartContext } from "./CartContext";
import shopData from "../Datas/shopData";

function ShopList() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { toCheck } = useContext(CartContext);
  const product = shopData.find((item) => item.id === Number(id));

  // 添加调试信息
  console.log("shopList-ID:", id, "Product:", product);

  const handleNavigate = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  if (!product) {
    return <div>商品が見つかりませんでした。</div>; // 产品未找到的提示
  }
  return (
    <div className="main-container">
      <div className="menu_shop_list">
        <div className="menu_top_return">
          <Link to="/" onClick={handleNavigate}>
            ヘッダー
          </Link>
        </div>
        <div
          className="shop_bac_img"
          style={{ backgroundImage: `url(${product.images})` }}
        >
          <div className="shop_bac_txt">
            <img
              width="80"
              height="80"
              alt=""
              src="	https://wrpcxa.b-merit.jp/uploads/shop/9f3e8cee1f75a9793b66ed19278ba218.jpg?ver=20230701100245"
            />
            <div className="bac_txt_right">
              <h1>{product.name}</h1>
              <p className="bac_list_position">{product.listPosition}</p>
              <p className="bac_tel">(415) 425-9345</p>
            </div>
          </div>
        </div>
      </div>
      <h2 className="good_select">商品を選択する</h2>
      <div className="shopList_div">
        <div className="shop_all">
          {product.shop.map((shop, index) => (
            <div key={index} className="coupon-inner">
              <div className="coupon_img">
                <img alt="" src={shop.image} />
              </div>
              <div className="coupon_txt">
                <div className="coupon_txt_top">
                  <p className="red">全員</p>
                  <p>{shop.name}</p>
                </div>
                <p className="coupon_price">￥{shop.price.toLocaleString()}</p>
                <p>{shop.detail}</p>
              </div>
              <div className="coupon_add_cart">
                <Link
                  to={`/toCheck/${product.id}/${shop.id}`}
                  onClick={() => toCheck(shop.id)}
                >
                  メニューを追加して予約
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ShopList;
