import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";
import { Link } from "react-router-dom";
import menuData from "../Datas/menuData";

function Main() {
  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="txt_img_two">
          <div className="two_img_inner">
            <div className="p-mainv__inner">
              <div className="p-mainv__textContainer-inner">
                <img alt="" src="/head/head1.png" />
              </div>
              <div className="p-mainv_img_two">
                <img
                  width="560"
                  height="446"
                  alt=""
                  src="https://www.haruka.co.jp/wp-content/themes/original-theme/assets/img/home/sp/mainv.jpg"
                />
                <div
                  className="p-mainv__movie c-movie js_mainv_movie pc_tb is_animate"
                  data-scroll=""
                  data-scroll-speed="-0.5"
                  data-scroll-position="top"
                >
                  <video
                    src="https://www.haruka.co.jp/wp-content/themes/original-theme/assets/img//common/image.mp4"
                    playsinline=""
                    autoplay=""
                    muted=""
                    loop=""
                    className="c-movie__item"
                  ></video>
                </div>
              </div>
            </div>
            <div className="navi_div">
              <ul className="p-mainv_navi">
                <li>menu</li> <li>List of salons</li> <li>catalogue</li>
                <li>frequently asked questions</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="p-topics">
          <div className="topics__container">
            <div className="topics_img">
              <img
                alt=""
                src="https://www.haruka.co.jp/wp-content/uploads/2024/09/1c55416f8d2a829e04b63df45de3233d.png"
              />
            </div>
            <div className="topics_txt">
              <div className="topics_txt_top">
                <div className="inner_txt_left">
                  <div className="left_first">
                    <div
                      className="topics__important__detail-category"
                      _msttexthash="203008"
                      _msthash="143"
                    >
                      / special menu
                    </div>
                    <div
                      className="topics__important__detail-category"
                      _msttexthash="74815"
                      _msthash="144"
                    >
                      2024.09.06
                    </div>
                  </div>
                  <h3
                    className="topics__important__title"
                    _msttexthash="1793207"
                    _msthash="145"
                  >
                    [New menu] Parisienne Lash Lift will be available now!
                  </h3>
                </div>
                <div className="inner_txt_right">
                  <h2
                    className="topics__title"
                    _msttexthash="80951"
                    _msthash="142"
                  >
                    topics
                  </h2>
                </div>
              </div>
              <div className="topics__nav__bar"></div>
              <div className="topics_all_imgs">
                <img
                  alt=""
                  src="https://www.haruka.co.jp/wp-content/uploads/2024/10/2.jpg"
                />
                <img
                  alt=""
                  src="https://www.haruka.co.jp/wp-content/uploads/2024/10/1.jpg"
                />
                <img
                  alt=""
                  src="https://www.haruka.co.jp/wp-content/uploads/2024/09/bfbad6b211b88780cf8eea224e516cb8.png"
                />
                <img
                  alt=""
                  src="https://www.haruka.co.jp/wp-content/uploads/2024/07/unnamed.png"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="p-menu">
          <div className="menu_inner">
            <h2>menu</h2>
            <div className="menu_img">
              <div>
                <img
                  width="363"
                  height="512"
                  alt=""
                  src="	https://www.haruka.co.jp/wp-content/uploads/2022/10/f8d76d745c5858fc1fad01193b39951b.jpg"
                />
                <div className="jap_eng">
                  <h3 className="p-menu__slider-title">眉カット</h3>
                  <p className="p-menu__slider-text">eyebrow cut</p>
                </div>
              </div>
              <div>
                <img
                  width="363"
                  height="512"
                  alt=""
                  src="	https://www.haruka.co.jp/wp-content/uploads/2022/10/a33093ab8e486d2256e191caf5699d05.jpg"
                />
                <div className="jap_eng">
                  <h3 className="p-menu__slider-title">ヘアメイク</h3>
                  <p className="p-menu__slider-text">hair makeup</p>
                </div>
              </div>
              <div>
                <img
                  width="363"
                  height="512"
                  alt=""
                  src="	https://www.haruka.co.jp/wp-content/uploads/2022/10/ef90f48ac6933ca23e40e9261aa465b0.jpg"
                />
                <div className="jap_eng">
                  <h3 className="p-menu__slider-title">ヘアメイクレッスン</h3>
                  <p className="p-menu__slider-text">hair makeup lesson</p>
                </div>
              </div>
              <div>
                <img
                  width="363"
                  height="512"
                  alt=""
                  src="	https://www.haruka.co.jp/wp-content/uploads/2022/10/eaf845162656d1bbd539ac60d0a6809c.jpg"
                />
                <div className="jap_eng">
                  <h3 className="p-menu__slider-title">ネイル</h3>
                  <p className="p-menu__slider-text">nail</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-gallery">
          <div className="gallery_inner">
            <h2>catalog</h2>
            <div className="four_catagory">
              <div className="article_gallery">
                <h3 className="p-gallery__sliderHeader-title">hair</h3>
              </div>
              <div className="images_gallery">
                {menuData
                  .filter((e) => e.id >= 1 && e.id <= 5)
                  .map((menu, index) => (
                    <div key={index}>
                      <img alt="" src={menu.images} />
                      <h3 className="p-gallery__sliderItem-title">
                        {menu.name}
                      </h3>
                    </div>
                  ))}
              </div>
            </div>

            <div className="four_catagory">
              <div className="article_gallery">
                <h3 className="p-gallery__sliderHeader-title">nail</h3>
              </div>
              <div className="images_gallery">
                {menuData
                  .filter((e) => e.id >= 6 && e.id <= 10)
                  .map((menu, index) => (
                    <div key={index}>
                      <img alt="" src={menu.images} />
                      <h3 className="p-gallery__sliderItem-title">
                        {menu.name}
                      </h3>
                    </div>
                  ))}
              </div>
            </div>

            <div className="four_catagory">
              <div className="article_gallery">
                <h3 className="p-gallery__sliderHeader-title">eyebrows</h3>
              </div>
              <div className="images_gallery">
                {menuData
                  .filter((e) => e.id >= 11 && e.id <= 15)
                  .map((menu, index) => (
                    <div key={index}>
                      <img alt="" src={menu.images} />
                      <h3 className="p-gallery__sliderItem-title">
                        {menu.name}
                      </h3>
                    </div>
                  ))}
              </div>
            </div>

            <div className="four_catagory">
              <div className="article_gallery">
                <h3 className="p-gallery__sliderHeader-title">makeup</h3>
              </div>
              <div className="images_gallery">
                {menuData
                  .filter((e) => e.id >= 16 && e.id <= 20)
                  .map((menu, index) => (
                    <div key={index}>
                      <img alt="" src={menu.images} />
                      <h3 className="p-gallery__sliderItem-title">
                        {menu.name}
                      </h3>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <div className="p-brand">
          <div className="brand-inner">
            <video
              src="https://www.haruka.co.jp/wp-content/themes/original-theme/assets/img/home/brand.mp4"
              playsInline
              autoPlay
              muted
              loop
              className="video-background"
            ></video>

            <div className="brand_content">
              <div className="p-brand__message-img js_scroll_event is_active">
                <figure
                  className="js_brand_01"
                  data-scroll=""
                  data-scroll-speed="0.45"
                >
                  <img
                    src="https://www.haruka.co.jp/wp-content/themes/original-theme/assets/img/home/brand_01.jpg"
                    alt=""
                    loading="”lazy”"
                  />
                </figure>
                <figure
                  className="js_brand_02"
                  data-scroll=""
                  data-scroll-speed="-0.15"
                >
                  <img
                    src="https://www.haruka.co.jp/wp-content/themes/original-theme/assets/img/home/brand_02.jpg"
                    alt=""
                    loading="”lazy”"
                  />
                </figure>
                <figure
                  className="js_brand_03"
                  data-scroll=""
                  data-scroll-speed="-0.75"
                >
                  <img
                    src="https://www.haruka.co.jp/wp-content/themes/original-theme/assets/img/home/brand_03.jpg"
                    alt=""
                    loading="”lazy”"
                  />
                </figure>
              </div>
              <div className="p-brand__message-detail">
                <div className="brand_txt_left">
                  <div>
                    <p>
                      无论如何，
                      <br />
                      它看起来像一个真正的敌人。
                    </p>
                    <p>
                      不到一分钟，
                      <br /> 我想 改变主意。
                    </p>
                    <p>
                      塔马尼，
                      <br /> 我全心全意地试图进入它里。
                    </p>
                    <p>
                      当时机成熟时，离开这个地方 前田，
                      <br /> 我感到平静。
                    </p>
                    <p>
                      Hairmeic 喉咙美人 Puro但 <br />
                      才能持续陪伴客户在
                    </p>
                    <p>
                      请随时 光临。 拿 <br />
                      我想 待在一个地方。
                    </p>
                  </div>
                  <div className="brand_h2">
                    <h2 className="c-title__enVertical __right hidden">
                      品牌声明
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-gallery">
          <div className="gallery_inner">
            <h2>column</h2>
            <div className="swiper p-special__archive c-slick__right-slider js_slider_special">
              <div className="column_slide">
                <div className="p-special__archiveItem-img">
                  <img
                    alt=""
                    src="https://www.haruka.co.jp/wp-content/uploads/2024/08/b4191b7a9260176c302222d7f7bf60c9.jpg"
                  />
                </div>
                <div className="p-special__archiveItem-detail">
                  <h3>介绍 3 岁 753 发型的流行饺子发法</h3>
                  <p>
                    即使是头发仍然稀疏的 3
                    岁孩子，也可以轻松增加头发的体积，给人以华丽的印象。
                  </p>
                  <p className="time_detail">2024.10.01</p>
                </div>
              </div>
            </div>
            <div className="swiper p-special__archive c-slick__right-slider js_slider_special">
              <div className="column_slide">
                <div className="p-special__archiveItem-img">
                  <img
                    alt=""
                    src="https://www.haruka.co.jp/wp-content/uploads/2022/10/de096a9306ec33c53306b5adc9908f69-1.jpg"
                  />
                </div>
                <div className="p-special__archiveItem-detail">
                  <h3>
                    着物の着付けで必要なもの一覧リスト｜あると便利な小物一式も紹介
                  </h3>
                  <p>
                    着物の着付けには様々なものが必要となる上に、聞きなれない、難しい名称のアイテムも多く存在します。そのため、「着…
                  </p>
                  <p className="time_detail">2024.10.01</p>
                </div>
              </div>
            </div>

            <div className="swiper p-special__archive c-slick__right-slider js_slider_special">
              <div className="column_slide">
                <div className="p-special__archiveItem-img">
                  <img
                    alt=""
                    src="	https://www.haruka.co.jp/wp-content/uploads/2024/02/f0a37455ba7159121f73263400883a44.jpg"
                  />
                </div>
                <div className="p-special__archiveItem-detail">
                  <h3>
                    【10月分UP】アトリエはるかプロデュース！カプカプ川和
                    オリジナルカレンダー
                  </h3>
                  <p>
                    2013年度より横浜市の地域作業所「カプカプ川和」さんより作品の提供を受けて、その中からアトリエはるかが選んで…
                  </p>
                  <p className="time_detail">2024.10.01</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="l-footer-contact">
          <div className="l-container-s">
            <div className="contact_div_left">
              <h2>contact</h2>
            </div>
            <div className="contact_div_right">
              <div className="contact_right_txt">
                <h3>ご利用前のご不明点やご質問 等</h3>
                <div className="right_txt_link">
                  <Link to="/contact">お問い合わせフォーム</Link>
                </div>
              </div>
              <div className="contact_right_txt">
                <h3>ご利用後のご意見やご感想 等</h3>
                <div className="right_txt_link">
                  <Link to="/contact">ご意見･ご感想フォーム</Link>
                </div>
              </div>
              <div className="contact_right_txt1">
                <p>
                  ※ご予約および予約内容の変更は各サロンに直接ご連絡ください。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Main;
