const shopData = [
  {
    id: 1,
    name: "アトリエはるか さっぽろポールタウン店",
    listPosition: "北海道 札幌市中央区南四条西4 さっぽろ地下街ポールタウン内",
    position:
      "札幌市営地下鉄南北線「すすきの」駅直結地下鉄「大通」駅より徒歩5分",
    list: [
      "#眉カット",
      "#ヘアメイク",
      "#ヘアメイクレッスン",
      "#まつげパーマ",
      "#まつげエクステ",
      "#着付け",
      "#ブライダル",
      "#卒業式",
      "#七五三",
      "#men's",
    ],
    shop: [
      {
        id: 1,
        name: "≪1番人気≫眉カット＋クイックワックス",
        detail:
          "ハサミやシェーバーで眉形を整え、フィルム式ワックスシートを使用し眉回りのうぶ毛もキレイに取り除いたあと、ペンシル・マニキュア・パウダーを使って眉メイクを仕上げます。営業時間外の予約は時間外料金【8時台2,200円／9時台1,100円】を頂戴します。",
        price: "3300",
        time: "20分",
        image:
          "https://wrpcxa.b-merit.jp/uploads/coupon/620f03b9531db4641502d54b755d9e6c.jpg?ver=20241001104937",
      },
      {
        id: 2,
        name: "おススメ★眉カット",
        detail:
          "ハサミやシェーバーで眉形を整え、ペンシル・マスカラ・パウダーを使って眉メイクを仕上げます。 ＊営業時間外のご予約は時間外料金を頂戴いたします",
        price: "1980",
        time: "10分",
        image:
          "https://wrpcxa.b-merit.jp/uploads/coupon/770fd24de801408c557085b47281891e.png?ver=20240918173919",
      },
      {
        id: 3,
        name: "≪人気メニュー≫メンズ眉カット ＋クイックワックス",
        detail:
          "男性向け眉カット。ハサミやシェーバーで眉形を整え、フィルム式ワックスシートを使用し眉回りのうぶ毛もキレイに取り除きます。お好みにあわせて眉メイクを仕上げます。営業時間外の予約は時間外料金【8時台2,200円／9時台1,100円】を頂戴します。 ＊営業時間外のご予約は時間外料金を頂戴いたします",
        price: "3850",
        time: "20分",
        image:
          "	https://wrpcxa.b-merit.jp/uploads/coupon/40dd369caceaa1b95314c81624f7024e.jpg?ver=20241001105004",
      },
    ],
    images:
      "	https://www.haruka.co.jp/wp-content/uploads/2022/10/19ac59815977db8963d19aff47916663-5.png",
  },
  {
    id: 2,
    name: "アトリエはるか 大丸札幌店",
    listPosition: "",
    position: "JR「札幌」駅直結 大丸札幌店 3階 キキヨコチョ内",
    list: [
      "#眉カット",
      "#ヘアメイク",
      "#まつげパーマ",
      "#まつげエクステ",
      "#着付け",
      "#ブライダル",
      "#卒業式",
      "#七五三",
      "#men's",
    ],
    shop: [
      {
        id: 1,
        name: "【新メニュー】パリジェンヌラッシュリフト",
        detail:
          "パリジェンヌはまつ毛を根元から80度立ち上げる目頭目尻の矯正効果によって、まぶた全体をリフトアップ。自然ときわだつ目元に。根本からパッチリ、かつナチュラルに仕上がります。",
        price: "6600",
        time: "50分",
        image:
          "https://wrpcxa.b-merit.jp/uploads/coupon/8d4f60653583eda370aaa1d154c6b0d5.jpg?ver=20241001165650",
      },
    ],
    images: "	https://www.haruka.co.jp/wp-content/uploads/2022/10/DS1.jpg",
  },
  {
    id: 3,
    name: "アトリエはるか 新宿京王モール アネックス店",
    listPosition: "",
    position:
      "京王新線、都営地下鉄新宿線、都営地下鉄大江戸線「新宿」駅改札口よりすぐ JR線「新宿」駅南口より徒歩3分 京王モール アネックス ※京王モールと京王モールアネックスは別の通りとなります",
    list: [
      "#眉カット",
      "#ヘアメイク",
      "#ヘアメイクレッスン",
      "#定額ネイル",
      "#まつげパーマ",
      "#まつげエクステ",
      "#着付け",
      "#ブライダル",
      "#卒業式",
      "#七五三",
      "#men's",
    ],
    shop: [
      {
        id: 1,
        name: "大好評につき10月14日まで⭐！ハンドワンカラージェル4070円→3740円！",
        detail:
          "京王モールアネックス店限定！少しお得にネイルをしませんか？※ジェルオフや甘皮ケアを希望の方は必ず別途オフやケアメニューをご選択ください。",
        price: "3740",
        time: "25分",
        image:
          "https://wrpcxa.b-merit.jp/uploads/coupon/675fb032aa84e2a301dae8055bfb6ef8.jpg?ver=20241001154533",
      },
      {
        id: 2,
        name: "【秋ネイル】シンプルアート",
        detail:
          "＜9/14～10/31限定＞画像の限定アート2種類が通常5,940円のところ、550円OFFの5,390円で楽しめます。※カラー変更可",
        price: "5390",
        time: "40分",
        image:
          "	https://wrpcxa.b-merit.jp/uploads/coupon/681304fdd57a56c6004af1a8c02af25c.png?ver=20240905174728",
      },
    ],
    images: "https://www.haruka.co.jp/wp-content/uploads/2022/10/082-5.jpg",
  },
  {
    id: 4,
    name: "アトリエはるか 東京八重洲店",
    listPosition: "",
    position:
      "JR線「東京」駅八重洲地下中央口より徒歩3分 東京メトロ丸の内線「東京」駅より八重洲方面へ徒歩10分 外堀地下3番通り",
    list: [
      "#眉カット",
      "#ヘアメイク",
      "#ヘアメイクレッスン",
      "#定額ネイル",
      "#まつげパーマ",
      "#まつげエクステ",
      "#着付け",
      "#ブライダル",
      "#卒業式",
      "#七五三",
      "#men's",
    ],
    shop: [
      {
        id: 1,
        name: "【秋ネイル】トレンドアート",
        detail:
          "＜9/14～10/31限定＞画像の限定アート2種類が通常7,040円のところ、550円OFFの6,490円で楽しめます。※カラー変更可",
        price: "6490",
        time: "40分",
        image:
          "	https://wrpcxa.b-merit.jp/uploads/coupon/bb0d00f0e7871d031222c890c373dc19.png?ver=20240905185437",
      },
    ],
    images:
      "	https://www.haruka.co.jp/wp-content/uploads/2022/10/b78867c22bc5541377b5a5f0ddf103c2-5.jpg",
  },
  {
    id: 5,
    name: "アトリエはるか アトレ上野店",
    listPosition: "",
    position:
      "アトレ上野EAST B1F JR山手線 上野駅中央改札より約3分 東京メトロ銀座線 上野駅よりJR上野駅方面へ徒歩約3分 日比谷線 上野駅　昭和通り北方面改札を出てJR中央改札方面へ徒歩約5分",
    list: [
      "#眉カット",
      "#ヘアメイク",
      "#ヘアメイクレッスン",
      "#定額ネイル",
      "#まつげパーマ",
      "#着付け",
      "#ブライダル",
      "#卒業式",
      "#men's",
    ],
    shop: [
      {
        id: 1,
        name: "【お得】通常7282円→6820円/カラーグラデーション＋ホームケアセット⭐",
        detail:
          "通常7282円→6820円/大人気カラーグラデーションのジェルネイル＋ホームケア用ハンドクリーム付き♪さらに今だけ…ハンドパックをプレゼント！※オフが必要な場合、別途選択をお願い致します。",
        price: "6820",
        time: "30分",
        image:
          "	https://wrpcxa.b-merit.jp/uploads/coupon/057eb3c1cc1353de95812103a66a9468.jpg?ver=20241005174211",
      },
      {
        id: 2,
        name: "【選べる飾り付☆】トレンドヘアセット　¥5720～",
        detail:
          "ヘアセットした後お好きな飾りを選んでお付けするクーポン♪※選ぶ飾りによってお値段が異なります※髪が濡れている場合ドライ料金別途¥1100頂戴しております",
        price: "5720",
        time: "20分",
        image:
          "	https://wrpcxa.b-merit.jp/uploads/coupon/8c278147b92a0262e72fa4136a0cdaaa.jpg?ver=20241005174211",
      },
      {
        id: 3,
        name: "パリジェンヌラッシュリフト　6,600円",
        detail:
          "パリジェンヌはまつ毛を根元から80度立ち上げる目頭目尻の矯正効果によって、まぶた全体をリフトアップ。自然ときわだつ目元に。根本からパッチリ、かつナチュラルに仕上がります。",
        price: "6600",
        time: "50分",
        image:
          "	https://wrpcxa.b-merit.jp/uploads/coupon/86a86c67eb8254537cc67b6aa7329a43.jpg?ver=20241005171434",
      },
      {
        id: 4,
        name: "眉カット＋クイックワックス",
        detail:
          "＜00・20・40分～のご予約をお願いします＞ワックスシートでキレイが長持ちします。椅子に座ったままの施術となります。＊営業時間外のご予約は時間外料金を頂戴いたします",
        price: "3300",
        time: "20分",
        image:
          "	https://wrpcxa.b-merit.jp/uploads/coupon/68bb11210f13ba45160e59e27b344541.jpg?ver=20241005171434",
      },
      {
        id: 5,
        name: "大人気★フルメイク+ヘアセット+眉カット",
        detail:
          "結婚式やパーティーにおススメ★いろいろなシーンご利用いただけます。＊営業時間外のご予約は時間外料金を頂戴いたします",
        price: "8690",
        time: "50分",
        image:
          "https://wrpcxa.b-merit.jp/uploads/coupon/298f89abb8dc86654896b27d4a1ffb3d.jpg?ver=20241005171434",
      },
      {
        id: 6,
        name: "No.1!!【ハンド】カラーグラデーション+パラジェル",
        detail:
          "ベースは爪を削らないジェルを使用、クリアにカラーグラデーション。＊オフご希望の方は【メニューを追加して予約する】よりお選びください",
        price: "6270",
        time: "30分",
        image:
          "	https://wrpcxa.b-merit.jp/uploads/coupon/dc475d876691e93c1f2023c57cdef63c.jpg?ver=20241005171434",
      },
    ],
    images:
      "	https://www.haruka.co.jp/wp-content/uploads/2023/04/3cb287ba62971c3c2a805892777896cb-2.jpg",
  },
];

export default shopData;
