import React, { useState, useContext } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/menu.css";
import { Link } from "react-router-dom";
import shopData from "../Datas/shopData";

function Menu() {
  const handleNavigate = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="main-container">
      <Header />
      <div className="sub_main">
        <div className="inner-section">
          <div className="img_area">
            <h1>salon</h1>
            <p>サロン一覧</p>
          </div>
        </div>
      </div>
      <section id="sec_item" data-bdf="scrl,on" className="onoff bg_gray on">
        <div className="inner-section">
          <div className="area_north">
            <p className="en">area</p>
            <h2>北海道</h2>
          </div>
        </div>
        <ul className="shop">
          {shopData.map((shop, index) => (
            <li key={index}>
              <img alt="" src={shop.images} />
              <div className="name_position">
                <h3>{shop.name}</h3>
                <p>{shop.position}</p>
              </div>
              <div className="shop_list">
                <div className="shop_list_top">
                  {shop.list.map((list, index) => (
                    <p key={index}>{list}</p>
                  ))}
                </div>
                <div className="shop_list_btn">
                  <Link
                    to={`/shopList/${shop.id}`}
                    onClick={handleNavigate}
                    className="btn_a_div"
                  >
                    Web予約
                  </Link>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </section>
      <Footer />
    </div>
  );
}

export default Menu;
