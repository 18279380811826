import React, { useContext, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { CartContext } from "./CartContext";
import Footer from "./Footer";
import "../css/checkout.css";

function CheckOut() {
  const { productId, shopId } = useParams();
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState({});
  const { getShopByProductIdAndShopId } = useContext(CartContext);
  const shop = getShopByProductIdAndShopId(Number(productId), Number(shopId));
  const [msg, setMsg] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    say: "",
    mei: "",
    tel: "",
    email: "",
    gender: "",
    firstTime: "",
  });
  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "姓は必須です。";
    if (!formData.lastName) newErrors.lastName = "名は必須です。";
    if (!formData.say) newErrors.say = "セイは必須です。";
    if (!formData.mei) newErrors.mei = "メイは必須です。";
    if (!formData.tel) newErrors.tel = "電話番号は必須です。";
    if (!formData.email) newErrors.email = "メールアドレスは必須です。";
    if (!formData.gender) newErrors.gender = "性別は必須です。";
    if (!formData.firstTime)
      newErrors.firstTime = "ご来店は初めてですか? は必須です。";

    return newErrors;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (Object.keys(errors).length === 0) {
      navigate("/success");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  if (!shop) {
    return <div>商品が見つかりませんでした。</div>;
  }

  return (
    <div className="main-container">
      <h2 className="good_select">お客様情報を入力してください</h2>
      <div className="shopList_div">
        <div className="shop_all">
          <table className="tacheck">
            <tbody>
              <tr>
                <th width="30%">クーポン</th>
                <td>
                  {shop.name} （{shop.time} ￥{shop.price}）
                </td>
              </tr>
              <tr>
                <th>所要時間(目安)</th>
                <td>{shop.time}</td>
              </tr>
              <tr>
                <th>お支払い予定金額</th>
                <td>
                  ￥{shop.price}
                  <br />
                  <span className="caution">
                    ※実際のお支払い金額と異なる場合があります。
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="shopList_div">
        <div id="menu-footer">
          <p className="info">
            <span className="must">※</span>は必須項目
          </p>

          <table className="taform fomes">
            <tbody>
              <tr>
                <th className="form-head sp-block">
                  姓<span className="caution">※</span>
                </th>
                <td>
                  <input
                    type="text"
                    name="name_sei"
                    placeholder="姓"
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                  <br />
                  {formErrors.firstName && (
                    <span className="error">{formErrors.firstName}</span>
                  )}
                </td>
                <th className="form-subhead sp-block right">
                  名<span className="caution">※</span>
                </th>
                <td>
                  <input
                    type="text"
                    name="name_mei"
                    placeholder="名"
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                  <br />
                  {formErrors.lastName && (
                    <span className="error">{formErrors.lastName}</span>
                  )}
                </td>
              </tr>
              <tr>
                <th className="form-head sp-block">
                  セイ<span className="caution">※</span>
                </th>
                <td>
                  <input
                    type="text"
                    name="name_kn_sei"
                    placeholder="セイ"
                    value={formData.say}
                    onChange={handleChange}
                  />
                  <br />
                  {formErrors.say && (
                    <span className="error">{formErrors.say}</span>
                  )}
                </td>
                <th className="form-subhead sp-block right">
                  メイ<span className="caution">※</span>
                </th>
                <td>
                  <input
                    type="text"
                    name="name_kn_mei"
                    placeholder="メイ"
                    value={formData.mei}
                    onChange={handleChange}
                  />
                  <br />
                  {formErrors.mei && (
                    <span className="error">{formErrors.mei}</span>
                  )}
                </td>
              </tr>
            </tbody>
          </table>

          <table className="taform fomes">
            <tbody>
              <tr>
                <th className="form-head sp-block">
                  電話番号<span className="caution">※</span>
                  <br className="sp-hidden" />
                  （ハイフンなし）
                </th>
                <td>
                  <input
                    type="tel"
                    name="tel"
                    placeholder="例:09000000000"
                    style={{ width: "737px" }}
                    value={formData.tel}
                    onChange={handleChange}
                  />
                  <br />
                  {formErrors.tel && (
                    <span className="error">{formErrors.tel}</span>
                  )}
                </td>
              </tr>
              <tr>
                <th className="form-head sp-block">
                  メールアドレス<span className="caution">※</span>
                </th>
                <td>
                  <input
                    type="email"
                    name="mail"
                    className="mailCheck"
                    placeholder="例:sell@harukamy.xyz"
                    style={{ width: "737px" }}
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <br />
                  {formErrors.email && (
                    <span className="error">{formErrors.email}</span>
                  )}
                </td>
              </tr>
            </tbody>
          </table>

          <table className="taform fomes">
            <tbody>
              <tr>
                <th className="form-head sp-block">
                  性別<span className="caution">※</span>
                </th>
                <td style={{ display: "flex" }}>
                  <input
                    type="radio"
                    name="gender"
                    value="1"
                    id="sex1"
                    checked={formData.gender === "1"}
                    onChange={handleChange}
                  />
                  <label htmlFor="sex1">&nbsp;女性</label>
                  <input
                    type="radio"
                    name="gender"
                    value="0"
                    id="sex0"
                    checked={formData.gender === "0"}
                    onChange={handleChange}
                  />
                  <label htmlFor="sex0">&nbsp;男性</label>
                  <input
                    type="radio"
                    name="gender"
                    value="2"
                    id="sex-null"
                    checked={formData.gender === "2"}
                    onChange={handleChange}
                  />
                  <label htmlFor="sex-null">&nbsp;選択しない</label>
                </td>
              </tr>
              {formErrors.gender && (
                <tr>
                  <td colSpan="2" style={{ paddingLeft: "20.2%" }}>
                    <span className="error">{formErrors.gender}</span>
                  </td>
                </tr>
              )}

              <tr>
                <th className="form-head sp-block">
                  ご来店は初めてですか?<span className="caution">※</span>
                </th>
                <td style={{ display: "flex" }}>
                  <input
                    type="radio"
                    name="firstTime"
                    value="0"
                    id="visit0"
                    checked={formData.firstTime === "0"}
                    onChange={handleChange}
                  />
                  <label htmlFor="visit0">&nbsp;はい</label>
                  <input
                    type="radio"
                    name="firstTime"
                    value="1"
                    id="visit1"
                    checked={formData.firstTime === "1"}
                    onChange={handleChange}
                  />
                  <label htmlFor="visit1">&nbsp;いいえ</label>
                </td>
              </tr>
              {formErrors.firstTime && (
                <tr>
                  <td colSpan="2" style={{ paddingLeft: "20.2%" }}>
                    <span className="error">{formErrors.firstTime}</span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <table className="taform fomes">
            <tbody>
              <tr>
                <th className="form-head sp-block">
                  質問1: 【新規の方のみ】
                  <br />
                  アトリエはるかを知ったきっかけは何ですか？
                </th>
              </tr>
              <tr>
                <td>
                  <input
                    id="question1-1"
                    type="radio"
                    name="answer[5185]"
                    value="11155"
                  />
                  <label htmlFor="question1-1">
                    アトリエはるか公式HPを見て
                  </label>
                  <input
                    id="question1-2"
                    type="radio"
                    name="answer[5185]"
                    value="11156"
                  />
                  <label htmlFor="question1-2">店舗を見て</label>
                  <input
                    id="question1-3"
                    type="radio"
                    name="answer[5185]"
                    value="11157"
                  />
                  <label htmlFor="question1-3">
                    アトリエはるか公式SNSを見て
                  </label>
                  <input
                    id="question1-4"
                    type="radio"
                    name="answer[5185]"
                    value="11158"
                  />
                  <label htmlFor="question1-4">
                    ホットペッパービューティーを見て
                  </label>
                  <input
                    id="question1-5"
                    type="radio"
                    name="answer[5185]"
                    value="11159"
                  />
                  <label htmlFor="question1-5">ご家族・友人の紹介</label>
                  <input
                    id="question1-6"
                    type="radio"
                    name="answer[5185]"
                    value="11160"
                  />
                  <label htmlFor="question1-6">
                    SNS（Instagram・Facebook・X）を見て
                  </label>
                  <input
                    id="question1-7"
                    type="radio"
                    name="answer[5185]"
                    value="11161"
                  />
                  <label htmlFor="question1-7">その他</label>
                </td>
              </tr>
            </tbody>
          </table>

          <table className="taform fomes">
            <tbody>
              <tr>
                <th className="form-head sp-block">
                  <span>
                    質問2: 【ご予約・ご来店・時間外営業の注意事項】
                    <br />
                    ----------
                    <br />
                    ＊当日のキャンセル、ご遅刻は店舗へお電話ください
                    <br />
                    　ご遅刻の場合はご案内できない場合がございます
                    <br />
                    ＊予約の都合上、10分程度お待ち頂く場合がございます
                    <br />
                    ＊営業時間外のご予約は時間外料金を頂戴いたします
                    <br />
                    　（8時台2,200円／9時台1,100円）
                  </span>
                </th>
              </tr>
              <br />
              <br />
              <tr>
                <th className="form-head sp-block">
                  <span>
                    質問3: 【各メニューの確認事項】
                    <br />
                    ----------
                    <br />
                    ◆ヘアメイク
                    <br />
                    ＊土･日･祝日は余裕を持ってご予約をお願いします
                    <br />
                    　（1メニュー60分／2メニュー90分）
                    <br />
                    ＊カールアイロン（コテ）のお取り扱いはございません
                    <br />
                    　ホットカーラーおよびストレートアイロンにてお仕上げいたします
                    <br />
                    ＊店内でのセルフメイク･ネイルはお断りしております
                    <br />
                    ＊所要時間20分を超えるヘアスタイルをご希望の場合は
                    <br />
                    　ご説明のうえ、別途料金を頂戴いたします
                    <br />
                    ＊頭髪が濡れている場合はドライ料金を頂戴いたします
                    <br />
                    　（1,100円）
                    <br />
                    ◆まつげ
                    <br />
                    ＊ご来店時はビューラー･マスカラをお控えください
                    <br />
                    ◆ネイル
                    <br />
                    ＊下記をご希望の場合必ずメニューをご選択ください
                    <br />
                    　・ジェル付替オフ ・甘皮ケア
                    <br />
                    　・パラジェル　　 ・補強や長さだし
                    <br />
                    　選択がないとご案内ができない場合がございます
                  </span>
                </th>
              </tr>
            </tbody>
          </table>

          <table className="taform fomes">
            <tbody>
              <tr>
                <th className="form-head sp-block">ご要望・ご相談</th>
              </tr>
              <tr>
                <td>
                  ＊メニューの変更・追加・複数名のご予約はお受けできません
                  <br />
                  ＊凝ったヘアスタイルやお飾りをご希望の場合は以下にご入力ください
                </td>
              </tr>
              <tr>
                <td>
                  <textarea
                    name="comment"
                    placeholder="ご要望・ご相談"
                    value={msg}
                    onChange={(e) => setMsg(e.target.value)}
                  ></textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="return_next">
        <div className="return_next_inner">
          <div className="return_shop_div">
            <Link to={`/shopList/${productId}`} className="return_shop">
              戻る
            </Link>
          </div>
          <div className="next_shop_div">
            <Link to="/checkout" className="next_shop" onClick={handleSubmit}>
              情報を提出する
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CheckOut;
