import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="bottom-container">
        <div className="base">
          <div className="foot_top_img">
            <img
              src="/head/foot.png"
              alt="髪の修復専門店　マルヘアデザイン"
              _mstalt="46638501"
            />
            <div className="foot_right_svg">
              <img
                src="/head/foot1.png"
                alt="髪の修復専門店　マルヘアデザイン"
                _mstalt="46638501"
              />
            </div>
          </div>
          <div className="foot_top_img">
            <div className="mid_left_imgs">
              <img
                width="354"
                height="140"
                src="https://www.haruka.co.jp/wp-content/themes/original-theme/assets/img/common/bnr_recruit.svg"
                alt="髪の修復専門店　マルヘアデザイン"
                _mstalt="46638501"
              />
              <div className="mid_right_imgs">
                <img
                  width="160"
                  height="51"
                  src="	https://www.haruka.co.jp/wp-content/themes/original-theme/assets/img/common/bnr_hh.jpg"
                  alt="髪の修復専門店　マルヘアデザイン"
                  _mstalt="46638501"
                />
                <img
                  width="160"
                  height="51"
                  src="	https://www.haruka.co.jp/wp-content/themes/original-theme/assets/img/common/bnr_ah.jpg"
                  alt="髪の修復専門店　マルヘアデザイン"
                  _mstalt="46638501"
                />
                <img
                  width="160"
                  height="51"
                  src="https://www.haruka.co.jp/wp-content/themes/original-theme/assets/img/common/bnr_hd.jpg"
                  alt="髪の修復専門店　マルヘアデザイン"
                  _mstalt="46638501"
                />
                <img
                  width="160"
                  height="51"
                  src="https://www.haruka.co.jp/wp-content/themes/original-theme/assets/img/common/bnr_rst.jpg"
                  alt="髪の修復専門店　マルヘアデザイン"
                  _mstalt="46638501"
                />
              </div>
            </div>
            <div className="mid_right_txt">
              <p className="l-footer__naviSub-title">others</p>
              <div className="naviSub_txt">
                <p>
                  <Link to="/" onClick={toTop}>
                    ヘッダー
                  </Link>
                </p>
                <p>
                  <Link to="/contact" onClick={toTop}>
                    お問い合わせ
                  </Link>
                </p>
                <p>
                  <Link to="/head/privacy.pdf" onClick={toTop} target="_blank">
                    プライバシーポリシー
                  </Link>
                </p>
                <p>
                  <Link to="/head/terms.pdf" onClick={toTop} target="_blank">
                    利用規約
                  </Link>
                </p>
                <p>
                  <Link to="/menu" onClick={toTop}>
                    店
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <p className="copy_right">
            Copyright atelier haruka co.,ltd. All rights reserved.
          </p>
        </div>
      </div>
    </>
  );
}

export default Footer;
