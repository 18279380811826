import React, { useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { CartContext } from "./CartContext";
import Footer from "./Footer";
import "../css/shopList.css";

function ToCheck() {
  const { productId, shopId } = useParams();
  const { getShopByProductIdAndShopId } = useContext(CartContext);

  const shop = getShopByProductIdAndShopId(Number(productId), Number(shopId));

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  if (!shop) {
    return <div>商品が見つかりませんでした。</div>;
  }

  return (
    <div className="main-container">
      <h2 className="good_select">商品詳細</h2>
      <div className="shopList_div">
        <div className="shop_all">
          <div className="coupon-inner">
            <div className="coupon_img">
              <img alt={shop.name} src={shop.image} />
            </div>
            <div className="coupon_txt">
              <p>{shop.name}</p>
              <p className="coupon_price">￥{shop.price.toLocaleString()}</p>
              <p>{shop.detail}</p>
            </div>
          </div>
        </div>
      </div>
      <p className="check_notice">
        ★ Welcome to haruka ★<br />
        当サロンを選んで頂き、ありがとうございます。
        <br />
        ※ 所要時間は目安です。出発時間の参考に=3
        <br />
        (土日のご予約はメニューの所要時間+30分前のご予約をお願いします)
        <br />
        ※ 人気メニュー＆おススメを★マークでご案内！ ※
        <br />
        当日ご来店時のメニュー相談OKです^^!
      </p>

      <div className="shopList_div">
        <div id="menu-footer">
          <h5>選択済みメニューを確認してください</h5>

          <table className="tamenu">
            <tbody>
              <tr>
                <th colspan="3">選択済み</th>
              </tr>
              <tr>
                <td className="selected">
                  <span className="bold">おススメ★眉カット</span>
                </td>
                <td className="t-right selected" width="100px">
                  ￥{shop.price}
                </td>
                <td className="t-right selected" width="50px">
                  {shop.time}
                </td>
              </tr>

              <tr className="lightgray">
                <th className="all">
                  合計
                  <br />
                  <span className="caution">
                    ※実際のお支払い金額と異なる場合があります。
                  </span>
                </th>
                <th
                  id="totalPrice"
                  className="all t-right"
                  width="100px"
                  price="0"
                  goingprice="0"
                >
                  ￥{shop.price}
                </th>
                <th
                  id="totalTime"
                  className="all t-right"
                  width="50px"
                  time="0"
                >
                  {shop.time}
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="return_next">
        <div className="return_next_inner">
          <div className="return_shop_div">
            <Link to={`/shopList/${productId}`} className="return_shop">
              戻る
            </Link>
          </div>
          <div className="next_shop_div">
            <Link to={`/checkout/${productId}/${shopId}`} className="next_shop">
              この内容で次へ
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ToCheck;
