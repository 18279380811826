import React, { createContext, useState, useEffect } from "react";
import shopData from "../Datas/shopData"; // 引入商品数据
export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(() => {
    const savedCartItems = localStorage.getItem("cartItems");
    return savedCartItems ? JSON.parse(savedCartItems) : [];
  });

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  const toCheck = (productId) => {
    console.log("tocheck-id", productId);

    setCartItems((prevItems) => {
      const existingItem = prevItems.find((item) => item.id === productId);

      if (existingItem) {
        return prevItems.map((item) =>
          item.id === productId ? { ...item } : item
        );
      } else {
        const newItem = {
          id: productId,
        };
        return [...prevItems, newItem];
      }
    });
  };

  const removeFromCart = (productId) => {
    setCartItems((prevItems) =>
      prevItems.filter((item) => item.id !== productId)
    );
  };

  const getShopByProductIdAndShopId = (productId, shopId) => {
    const product = shopData.find((item) => item.id === productId);
    if (product) {
      return product.shop.find((shop) => shop.id === shopId);
    }
    return null;
  };

  const clearCart = () => {
    setCartItems([]);
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        removeFromCart,
        getShopByProductIdAndShopId, // 替换为新的函数
        clearCart,
        toCheck,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
