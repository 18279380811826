const menuData = [
  {
    id: 1,
    name: "/ No.29",
    images: "https://www.haruka.co.jp/wp-content/uploads/2022/11/29_1-1.jpg",
  },
  {
    id: 2,
    name: "/ No.105",
    images: "https://www.haruka.co.jp/wp-content/uploads/2024/09/1-100.jpg",
  },
  {
    id: 3,
    name: "/ No.106",
    images: "https://www.haruka.co.jp/wp-content/uploads/2024/09/4-100.jpg",
  },
  {
    id: 4,
    name: "/ No.107",
    images: "https://www.haruka.co.jp/wp-content/uploads/2024/09/7-100.jpg",
  },
  {
    id: 5,
    name: "/ No.108",
    images: "https://www.haruka.co.jp/wp-content/uploads/2024/09/10-100.jpg",
  },
  {
    id: 6,
    name: "/ プチアート",
    images:
      "	https://www.haruka.co.jp/wp-content/uploads/2024/02/H4620_2310_B021_1SPR-1.jpg",
  },
  {
    id: 7,
    name: "/ シンプルアート",
    images:
      "	https://www.haruka.co.jp/wp-content/uploads/2023/03/H5940_0000_B001_3FAL.jpg",
  },
  {
    id: 8,
    name: "/ プレミアムアート",
    images:
      "https://www.haruka.co.jp/wp-content/uploads/2022/12/H9240_0000_B031_4WTR.jpg",
  },
  {
    id: 9,
    name: "/ トレンドアート",
    images:
      "https://www.haruka.co.jp/wp-content/uploads/2024/02/H7040_0000_C017_4WTR-1.jpg",
  },
  {
    id: 10,
    name: "/ デザインアート",
    images:
      "https://www.haruka.co.jp/wp-content/uploads/2023/05/H8140_0000_C036_3FAL.jpg",
  },
  {
    id: 11,
    name: "/ No.1 women’s シャープ",
    images:
      "https://www.haruka.co.jp/wp-content/uploads/2022/11/147ef86cce186a32f5f734e9d434c1dd.png",
  },
  {
    id: 12,
    name: "/ No.2 women’s アーチ",
    images:
      "https://www.haruka.co.jp/wp-content/uploads/2022/11/4a4753221a0c60bebbc85a21f76aa3e1.png",
  },
  {
    id: 13,
    name: "/ No.3 women’s ナチュラル",
    images:
      "https://www.haruka.co.jp/wp-content/uploads/2022/11/014bc22e8a553853b3e67aba97a0aa31.png",
  },
  {
    id: 14,
    name: "/ No.4 women’s ストレート",
    images:
      "https://www.haruka.co.jp/wp-content/uploads/2022/11/c35988b58cefd4b2ae462c47a9cd92fe.png",
  },
  {
    id: 15,
    name: "/ No.5 women’s ベーシック",
    images:
      "	https://www.haruka.co.jp/wp-content/uploads/2022/11/106aeaf6c05948e0ba1f1ff80af52008.png",
  },
  {
    id: 16,
    name: "/ No.11",
    images:
      "https://www.haruka.co.jp/wp-content/uploads/2024/04/e0a2aaa785559b2bf76ef5ba89b795a1.jpg",
  },
  {
    id: 17,
    name: "/ No.12",
    images:
      "https://www.haruka.co.jp/wp-content/uploads/2024/04/d7bc8b46f1743db861c4e8b4e33344b2.jpg",
  },
  {
    id: 18,
    name: "/ No.13",
    images:
      "https://www.haruka.co.jp/wp-content/uploads/2024/04/b9786a7b5c5335fd042150877d8466d9.jpg",
  },
  {
    id: 19,
    name: "/ No.14",
    images:
      "https://www.haruka.co.jp/wp-content/uploads/2024/04/d69019e10429c2a6f6f8d2c92440d0b0.jpg",
  },
  {
    id: 20,
    name: "/ No.15",
    images:
      "	https://www.haruka.co.jp/wp-content/uploads/2024/04/859beab67f55eb953d837028a04f79f4-1.jpg",
  },
];

export default menuData;
