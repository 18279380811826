import React from "react";
import "../css/paysuccess.css";
import Header from "./Header";
import Footer from "./Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const PaySuccess = () => {
  return (
    <>
      <Header />
      <div className="successDiv">
        <div className="success-container">
          <div className="modal-content">
            <div className="checkmark-circle">
              <FontAwesomeIcon icon={faCheck} />
              <div className="checkmark"></div>
            </div>
            <p>Pay Success</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PaySuccess;
